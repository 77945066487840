<template>
  <div class="py-6" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
  </div>
  <div class="bg-white shadow-sm">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <div class="d-md-flex align-items-center justify-content-between bg-white  pt-3 pb-3 pb-lg-5">
            <div class="d-md-flex align-items-center text-lg-start text-center ">
              <div class="me-3  mt-n8">
                <router-link :to="{name: 'ComboDetail', params: {code: combo.code}}" :title="combo.name">
                  <img :src="combo.thumbnail ? combo.thumbnail.link : '/assets/images/brand/logo/erobo.jpg'" :alt="combo.name"
                       class="avatar-xxl rounded bg-white object-fit-cover">
                </router-link>
              </div>
              <div class="mt-3 mt-md-0">
                <h1 class="mb-0 fw-bold me-3 ">{{ combo.name }}</h1>
                <div>
                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{
                      combo.courses.paginatorInfo.total
                    }} </span> khóa học</span>
                </div>
              </div>
            </div>
            <div class="mt-3 mt-lg-0 text-lg-start text-center">
              <router-link v-if="tab === 'share'" :to="{name: 'AdminComboAdd'}" class="btn btn-primary">
                <i class="fe fe-plus-circle"></i> Thêm combo
              </router-link>
            </div>
          </div>
          <ul class="nav nav-lt-tab" id="pills-tab" role="tablist">
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('share')" :class="tab === 'share' ? 'nav-link active' : 'nav-link'"
                 id="pills-share-tab" data-bs-toggle="pill" href="#pills-share" role="tab"
                 aria-controls="pills-share" aria-selected="true">Share combo</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="tab-content" id="pills-tabContent">
            <div :class="tab === 'share' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-share"
                 role="tabpanel" aria-labelledby="pills-share-tab">
              <div class="col-md-12">
                <div class="card pb-4">
                  <div class="card-header border-bottom-0">
                    <form class="d-flex align-items-center">
                      <input type="search" v-model="schoolFilter.name" class="form-control me-4" placeholder="Tên trường">
                    </form>
                  </div>
                  <table class="table mb-4 text-nowrap list">
                    <thead class="table-light">
                    <tr>
                      <th scope="col">Trường học</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(school, index) in schools.data" :key="'school' + index">
                      <td class="align-middle">
                        <div class="form-check">
                          <input v-if="school.isShared" :disabled="school.isOwner" v-model="school.isShared" v-on:click="removeSchoolCombo(school)" class="form-check-input" type="checkbox">
                          <input v-if="!school.isShared" v-model="school.isShared" v-on:click="addSchoolCombo(school)" class="form-check-input" type="checkbox">
                          <label class="form-check-label">
                            {{ school.name }}
                          </label>&nbsp;
                          <span v-if="school.isOwner" class="badge bg-danger">Chủ sở hữu</span>
                        </div>
                      </td>
                      <td class="text-muted align-middle border-top-0"></td>
                    </tr>
                    </tbody>
                  </table>
                  <v-pagination
                      v-if="schools.paginatorInfo.lastPage > 1"
                      v-model="schools.paginatorInfo.currentPage"
                      :pages="schools.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadSchools"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" :title="messageModalTitle"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import Modal from "@/components/Modal";
import ApiService from "@/core/services/api.service";
import {closeModal, getWhereCondition, openModal} from "@/core/services/utils.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "ComboStatistic",
  components: {
    Modal,
    VPagination
  },
  data() {
    return {
      combo: {
        name: "",
        thumbnail: {
          link: "/assets/images/avatar/avatar.jpg"
        },
        courses: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        },
        schools: {
          data: []
        }
      },
      schools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
      schoolFilter: {
        name: ""
      },
      tab: "share",
      sharedSchools: [],
      ownerSchools: []
    }
  },
  methods: {
    loadCombo() {
      let query = `query($code: String!) {
        combo(code: $code) {
          name
          code
          thumbnail {
            link
          }
          schools(first: 10000, page: 1) {
            data {
              id
              code
              comboSchoolPivot {
                is_owner
              }
            }
          }
          courses(first: 1, page: 1) {
            paginatorInfo {
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {code: this.$route.params.code})
          .then(({data}) => {
            if (data.data && data.data.combo) {
              this.combo = data.data.combo;
              this.loadSchools();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadSchools() {
      let where = this.getSchoolWhereCondition();

      let query = `query {
        schools(first: 5, page: ${this.schools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]${where}) {
          data {
            id
            code
            name
            subdomain
            status
            created_at
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.schools) {
              this.schools = data.data.schools;
              if (this.schools.data && this.schools.data.length) {
                this.schools.data.map((school) => {
                  school.isShared = this.schoolIsShared(school.code);
                  school.isOwner = this.schoolIsOwner(school.id);
                });
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    addUserCombo() {
      this.messageModalTitle = "Thêm combo";

      let query = `mutation($uid: String!, $combo_code: String!) {
        addUserCombo(uid: $uid, combo_code: $combo_code)
      }`;

      ApiService.graphql(query, {uid: this.addUserComboUID, combo_code: this.combo.code})
          .then(({data}) => {
            if (data.data && data.data.addUserCombo) {
              this.messageModalMessage = "Thêm combo thành công";
              this.messageModalType = "success";
              closeModal("addUserComboModal");
              openModal("messageModal");
              this.loadCombo();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("addUserComboModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("addUserComboModal");
            openModal("messageModal");
          });
    },
    getSchoolWhereCondition() {
      let filters = {};

      for (let key in this.schoolFilter) {
        if (key === "name") {
          if (this.schoolFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.schoolFilter[key] + "%"
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    setTab(tab) {
      this.tab = tab;
    },
    schoolIsShared(school_code) {
      return this.sharedSchools.includes(school_code);
    },
    schoolIsOwner(school_id) {
      return this.ownerSchools.includes(parseInt(school_id));
    },
    addSchoolCombo(school) {
      this.messageModalTitle = "Share khóa học";

      let query = `mutation($school_code: String!, $combo_code: String!) {
        addSchoolCombo(school_code: $school_code, combo_code: $combo_code)
      }`;

      ApiService.graphql(query, {school_code: school.code, combo_code: this.combo.code})
          .then(({data}) => {
            if (data.data && data.data.addSchoolCombo) {
              this.messageModalMessage = "Share khóa học thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              school.isShared = true;
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
              school.isShared = false;
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
            school.isShared = false;
          });
    },
    removeSchoolCombo(school) {
      this.messageModalTitle = "Share khóa học";

      let query = `mutation($school_code: String!, $combo_code: String!) {
        removeSchoolCombo(school_code: $school_code, combo_code: $combo_code)
      }`;

      ApiService.graphql(query, {school_code: school.code, combo_code: this.combo.code})
          .then(({data}) => {
            if (data.data && data.data.removeSchoolCombo) {
              this.messageModalMessage = "Bỏ share khóa học thành công";
              this.messageModalType = "success";
              school.isShared = false;
              openModal("messageModal");
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              school.isShared = true;
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            school.isShared = true;
            openModal("messageModal");
          });
    },
    getSharedSchools() {
      this.sharedSchools = [];
      this.ownerSchools = [];
      if (this.combo.schools.data && this.combo.schools.data.length) {
        let schools = this.combo.schools.data;
        for (let i = 0; i < schools.length; i++) {
          this.sharedSchools.push(schools[i].code);
          if (schools[i].comboSchoolPivot.is_owner === 1) {
            this.ownerSchools.push(parseInt(schools[i].id));
          }
        }
      }
    }
  },
  mounted() {
    this.loadCombo();
  },
  watch: {
    schoolFilter: {
      handler() {
        this.schools.paginatorInfo.currentPage = 1;
        this.loadSchools();
      },
      deep: true
    },
    "combo.schools.data": {
      handler() {
        this.getSharedSchools();
      },
      deep: true
    }
  }
}
</script>
